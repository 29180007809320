:root {
  --color-primary: #2c25e9;
  --color-hover: #2c25e9;
  --background-color: white;
  --text-color: #000;
  --header-title-color: #526d82;
  --search-box-background: #dde6ed;
  --icon-color: #969393;
  --custom-card-bg: white;
}

body.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --header-title-color: #8fa3b0;
  --search-box-background: #333;
  --icon-color: #ffffff;
  --custom-card-bg: #1f1f2d;
}
.content-profile {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-bottom: 10rem;
}
.profile--content-datails-body,
.profile--content-datails,
.profile--content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  background: var(--custom-card-bg);
  color: var(--text-color);
  border-radius: 20px;   border: 1px dotted rgba(54, 54, 54, 0.308);
  /* border: 1px dotted rgba(148, 148, 148, 0.308); */
  gap: 1rem;
  position: relative;
}
.profile--content-datails {
  border-radius: 20px 20px 0px 0px;
  padding-bottom: 1rem;
}
.profile--content-datails-body {
  border-radius: 0px 0px 20px 20px;
}
.img-user-profile {
  width: 160px;
  height: 160px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1),
    0 12px 40px rgba(0, 0, 0, 0.1);
}
.info-user-profile .info-name-profile {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  gap: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}

.info-user-profile div p:hover {
  transition: all 0.3s ease-in-out;
  color: #53d3e7;
}
.info-user-profile div span {
  margin-top: -23px;
  padding: 5px;
  border-radius: 50px;
  font-size: 10px;
  background-color: #38ee5059;
  border: 1px solid #4edd61b9;
}
.info-user-profile div span:hover {
  color: white;
  background-color: #38ee50;
}
.info-user-profile p:nth-of-type(1) {
  display: flex;
  gap: 1rem;
  
}
.info-user-profile p:nth-of-type(1) {
  margin-top: -10px;
}
.info-user-profile p:nth-of-type(1) span {
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  color: #505675;
}
.info-user-profile p:nth-of-type(1) span:hover {
  transition: all 0.3s ease-in-out;
  color: #53d3e7;
}
.header--details--profile {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-profile-edit {
  background: #2c25e9 !important; 
  border: none;
  border-radius: 10px;
  padding: 0.60rem;
  color: white;
}
.content--details-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content--details-profile label {
  color: #505675;
}
.row {
  flex-wrap: nowrap;
}
.button-absolute{
  position: absolute;
  top: 15px;
  right: 15px;
}
@media screen and (max-width: 980px) {
  .row {
    flex-wrap: wrap;
  }
  .header--details--profile h3 {
    font-size: 15px;
  }
  .button-profile-edit {
    background: #53d3e7;
    border: none;
    border-radius: 10px;
    padding: 0.5rem;
    color: white;
    font-size: 10px;
  }
  .profile--content-datails-body {
    font-size: 15px;
  }
}
.content-profile::-webkit-scrollbar{
    width: 0px;
}
@media screen and (max-width: 700px) {
  .info-user-profile p:nth-of-type(1) {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
 .info-name-profile{
    padding-bottom: 1.5rem;
  }
}


