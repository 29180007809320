:root {
  --color-primary: #2c25e9;
  --color-hover: #2c25e9;
  --background-color: white;
  --text-color: #000;
  --header-title-color: #526d82;
  --search-box-background: #dde6ed;
  --icon-color: #969393;
  --custom-card-bg: white;
}

body.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --header-title-color: #8fa3b0;
  --search-box-background: #333;
  --icon-color: #ffffff;
  --custom-card-bg: #1f1f2d;
}

.bi-search {
  color: #2c25e9;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 9999;
  color: #2c25e9 !important;
}
.spinner-container img {
  margin-bottom: 2rem;
}
.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin: 0;
  list-style: none;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: 8px 12px;
  text-decoration: none;
  transition: all 0.3s;
  border-radius: 4px;
}

.page-link:hover {
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
li {
  padding: 0 !important;
}

