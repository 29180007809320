.section__login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  /* background: linear-gradient(to bottom right, #2C25E9, #2C25E9); */
}

.form-signin {
  max-width: 400px;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"],
.form-signin input[type="password"] {
  border-radius: 10px;
  margin-bottom: 15px;
}

.form-signin input[type="email"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-google {
  background-color: #db4437;
  border: none;
  color: white;
  transition: background-color 0.3s ease;
}

.btn-google:hover {
  background-color: #c23321;
}

/* .shadow-lg {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15) !important;
} */
.border {
  width: 100%;
  height: auto;
  border: black;
  background: white !important;
  color: black !important;
  display: flex !important;
  justify-content: center;
  font-size: 15px !important;
  position: relative !important;
  margin-top: 15px;
  padding: 10px !important;
  cursor: pointer !important;
}
.fb {
  position: absolute;
  left: 10px;
}
.border div span {
  margin-left: 40px;
}

.google {
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  border: 0.5px solid;
  border-color: rgb(219, 219, 219);
  background: white;
  justify-content: center;
  text-align: center;
  position: relative;
}
.google span {
  position: absolute;
  left: 10px;
  bottom: 7px;
}

@media screen and (max-width: 768px) {
  .section__login {
    padding: 0px 20px 0px 20px;
  }
}
