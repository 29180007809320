.form-control{
    resize: none;
    form-sizing: content;
}
.content-form{
 display: flex;
 flex-wrap: wrap !important;
}
.container{
    overflow: auto;
    padding-bottom: 10rem;
    
}
.table-responsive{
    padding: 3rem !important;
    border: 1px dotted rgba(27, 27, 27, 0.308);
    border-radius: 20px !important;
}
.table-responsive::-webkit-scrollbar{
    width: 1px;
}

/* .dashboard--content::-webkit-scrollbar-track {
    border-radius: 20px;
  } */