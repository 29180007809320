:root {
  --color-primary: #2c25e9;
  --color-hover: #2c25e9;
  --background-color: white;
  --text-color: #000;
  --header-title-color: #526d82;
  --search-box-background: #dde6ed;
  --icon-color: #969393;
  --custom-card-bg: white;
  --border-content-dark: border: 1px dotted rgba(148, 148, 148, 0.308);
  --content-chats-text:#EFF0FF;
  --content-chats-text-users:#DCF9FC;
  --custom--close-chats:#E3E5E8;
  --custom--text-color-close-chats:#565775;
}

body.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --header-title-color: #8fa3b0;
  --search-box-background: #333;
  --icon-color: #ffffff;
  --custom-card-bg: #1f1f2d;
  --custom-card-tp-transparent: rgba(43, 43, 43, 0.219);
  --content-chats-text:#392E59;
  --content-chats-text-users:#2C3649;
  --custom--close-chats:#342E43;
  --custom--text-color-close-chats:#C1B1B9;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);
}

.btn-primary {
  background-color: var(--color-primary);
}

.btn-primary:hover {
  background-color: var(--color-hover);
}

.dashboard {
  width: auto;
  background-color: var(--dashboard-background);
  display: flex;
}

.dashboard--content {
  height: 100%;
  position: relative;
  background: var(--dashboard-background);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  flex: 1;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}

.content-header-dashboard {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  flex: 1;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--spinner-background);
  z-index: 9999;
  color: var(--color-primary) !important;
}

.spinner-container img {
  margin-bottom: 2rem;
}

.dashboard--content::-webkit-scrollbar {
  width: 0px;
}

.dashboard--content::-webkit-scrollbar-track {
  border-radius: 20px;
}

.dashboard--content::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 10px;
}

/* Additional CSS */
.content-header-sidebar {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.alert-icon-hover:hover {
  color: blue;
  transition: color 0.3s ease;
}

.notification {
  background-color: var(--notification-bg);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.content-text-info-ventas p {
  display: flex;
  flex-direction: column;
  padding: 0px;
  font-size: 20px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.span-ventas {
  color: var(--span-ventas-color);
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.content-alerts-notificaciones {
  display: flex;
  gap: 1rem;
}

.menu-responsive-content {
  display: none;
}

.Menu-responsive {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
}

@media screen and (max-width: 1500px) {
  .menu-responsive-content {
    display: flex;
    gap: 0.5rem;
  }

  .content-header-sidebar {
    flex-wrap: wrap;
    align-items: start;
    height: 150px;
  }

  .menu-phone {
    z-index: 99;
  }

  .Menu-responsive {
    display: flex;
    flex-direction: column;
  }

  .content-alerts-notificaciones {
    margin-top: 22px;
  }

  .content-text-info-ventas p {
    display: flex;
    flex-direction: column;
    padding: 0px;
    font-size: 16px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .span-ventas {
    color: var(--span-ventas-color);
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
}

.content-header-sidebar {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.content-alert {
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgb(124, 124, 124);
  cursor: pointer;
}

.alert-icon-hover:hover {
  color: blue;
  transition: all 0.3s ease-in-out;
}

.notification {
  background-color: #4ec8da;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.content-text-info-ventas p {
  display: flex;
  flex-direction: column;
  padding: 0px;
  font-size: 20px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.span-ventas {
  color: rgb(148, 148, 148);
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.content-alerts-notificaciones {
  display: flex;
  gap: 1rem;
}

.menu-responsive-content {
  display: none;
}

.Menu-responsive {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
}

@media screen and (max-width: 1500px) {
  .menu-responsive-content {
    display: flex;
    gap: 0.5rem;
  }

  .content-header-sidebar {
    flex-wrap: wrap;
    align-items: start;
    height: 150px;
  }

  .menu-phone {
    z-index: 99;
  }

  .Menu-responsive {
    display: flex;
    flex-direction: column;
  }

  .content-alerts-notificaciones {
    margin-top: 22px;
  }

  .content-text-info-ventas p {
    display: flex;
    flex-direction: column;
    padding: 0px;
    font-size: 16px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .span-ventas {
    color: rgb(148, 148, 148);
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
}

.Alert-chat-online-transparent {
  width: 100%;
  height: 100%;
  top: 0;

  z-index: 99;
  background-color: var(--custom-card-tp-transparent);
  position: absolute;
  transition: all .8s ease-in-out;
}

.content-chat-online-chats {
  width: 500px;
  height: 100%;
  top: 0;
  right: 0;
  background: var(--custom-card-bg);
  position: absolute;
  transition: all 1s ease-in-out;
}

.content-header-chats-online {
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-bottom: 1px dotted rgba(148, 148, 148, 0.308);
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.content-header h4{
  font-size: 20px;
}
.content-chats-headers-ssetings {
  display: flex;
  gap: 1rem;
}

.ssetings-poinst,
.close-chats {
  border-radius: 5px;
  width: 25px;
  height: 25px;
  font-size: 15px;
  background: var(--custom--close-chats);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color:var(--custom--text-color-close-chats) ;
}

.ssetings-poinst {
  align-items: top;
}

.close-chats:hover,
.ssetings-poinst:hover {
  background: #51b9c97a;
  color: #4ec8da;
}

.body-content {
  width: 100%;
  height: 80vh;
  padding-top: 3rem;
  padding-left: 3.7rem;
  padding-right: 3.7rem;
  border-bottom: 1px dotted rgba(148, 148, 148, 0.308);
}

.content-body-chats {
  overflow: auto;
  height: 100%;
}

.footer-content-chats {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.content-input-chats-text-content {
  width: 100%;
}

.input-chats-text-content {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  color: var(--text-color)
}

.input-chats-text-content:focus {
  border-color: rgb(255, 144, 0);

  outline: 0 none;
}

.send--content-buttons-chats {
  display: flex;
  justify-content: end;
}

.send--content-buttons-chats button {
  border: none;
  padding-top: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: .5rem;
  border-radius: 10px;
  background: #4ec8da;
  color: var(--text-color);
}

.content-chats-foto-name {
  display: flex;
  align-items: center;
}

.content-chats-foto-name-users{
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row-reverse;
}
.content-info {
  display: flex;
  align-items: center;
  gap: .5rem;
}
.content-info-user{
  display: flex;
  align-items: center;
  gap: .5rem;
}
.name {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
  margin-left: .5rem;
}

.time {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.cards-chats-message {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.cards-chats-message img {
  width: 35px;
  height: 35px;
  border-radius: 20px;
}
content-chats-foto-name-users img{
  width: 35px;
  height: 35px;
  border-radius: 20px;
  margin-left: 15px !important;
}
.menssage-content-chats-users,
.menssage-content-chats {
  border-radius: 10px;
  background-color: var(--content-chats-text);
  min-width: 150px;
  max-width: 400px;
  width: auto;
  min-width: 50px;
  height: auto;
  padding: .5rem;
  font-size: 14px;
  font-weight: 300;
}
.menssage-content-chats-users{
background-color: var(--content-chats-text-users);
}

.input-chats-text-content::-webkit-scrollbar,
.content-body-chats::-webkit-scrollbar {
  width: 0px;
}