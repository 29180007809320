body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  transition: background-color 5s ease, color 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);

}
html {
  scroll-behavior: smooth;
}
:root {
  /* Light mode colors */
  --color-primary: #2c25e9;
  --color-hover: #2c25e9;
  --background-color: #f9fdff;
  --text-color: #000;
  --dashboard-background: #f9fdff;
  --spinner-background: white;
}
body{
  overflow: hidden;
}
body.dark-mode {
  /* Dark mode colors */
  transition: background-color 5s ease, color 0.3s ease;
  --color-primary: #2c25e9;
  --color-hover: #2c25e9;
  --background-color: #0e1114;
  --text-color: #ffffff;
  --dashboard-background: #0e1114;
  --spinner-background: #333333;
}

.btn-primary {
  background-color: var(--color-primary);
}

.btn-primary:hover {
  background-color: var(--color-hover);
}

.dashboard {
  width: auto;
  background-color: var(--dashboard-background);
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;
}

.dashboard--content {
  height: 100%;
  position: relative;
  background: var(--dashboard-background);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  flex: 1;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.content-header-dashboard {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  flex: 1;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--spinner-background);
  z-index: 9999;
  color: var(--color-primary) !important;
}

.spinner-container img {
  margin-bottom: 2rem;
}



.dashboard--content::-webkit-scrollbar-track {
  border-radius: 20px;
}

.dashboard--content::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 10px;
}
@media screen and (max-width: 700px) {
    .dashboard--content{
      padding: 0 !important;
  }
}
/* 

@media screen and (max-width:  999px) {
  body{
    overflow: auto;
  }
  .dashboard{
    height: 100vh;
    gap:0px;
    padding:0px ;

  }
  .dashboard--content{
    flex: 1;
    height: 100%;
    border-radius: 0px;
  }
} */
