:root {
  --color-primary: #2c25e9;
  --color-hover: #2c25e9;
  --background-color: white;
  --text-color: #000;
  --header-title-color: #526d82;
  --search-box-background: #dde6ed;
  --icon-color: #969393;
  --custom-card-bg: white;
}

body.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --header-title-color: #8fa3b0;
  --search-box-background: #333;
  --icon-color: #ffffff;
  --custom-card-bg: #1f1f2d;
}
li {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 60px;
  padding-bottom: 5px;
}

.separar-letras:hover {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.nav-link {
  display: flex;
  gap: 1rem;
  min-width: 250px;
}

.nav-link.activo {
  color: rgba(255, 255, 255, 0.767) !important;
}
.icons {
  color:  var(--text-color);
}
.nav-link.nav-link.activo .icons{
  color: white;
}
.nav-link.activo {
  color: trf;
  background-color: #2c25e9;
  border-radius: 20px 20px 20px 20px !important;
  transition: all 0.3s ease-in-out;
}
.nav-link.activo span {
  color: white !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.content-nav-menu {
  background: var(--custom-card-bg);
  color: var(text-color);
  display: flex;
  position: relative;
  flex-direction: column !important;
  justify-content: space-between;
  height: 100vh;
}
.menu-phone {
  width: 300px;
  color: var(--text-color);
}
.menu-phone ul li a span {
  color: var(--text-color);
}
.logo-img {
  padding-top: 40px;
  padding-left: 20px;
}

.puntos {
  border-top: 2px dotted #747272;
  margin: 20px 0px;
  margin-bottom: 50px;
}
.img-sidebar-perfil {
  width: 40px;
  height: 40px;
  border-radius: 10px 10px 10px 10px;
}
.dropdown {
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 60px;
}
.dropdown div {
  padding-top: 0.5rem;
}
.dropdown div span {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 600;
  font-size: 14px;
}
.dropdown {
  display: flex;
  align-items: center;
}
.dropdown span:hover {
  color: #2c25e9;
}
.content-text-sidebar p {
  font-size: 14px;
  color: #747272;
}
.list-group{
  padding: 0px 100px 0px 20px;
}
@media screen and (max-width: 1500px) {
  .menu-phone {
    margin-left: -303px;
    transition: all 0.8s ease-in-out;
  }
  .open {
    margin-left: 0px;
  }
  .img-logo-responsive-hidden {
    display: none;
  }
}
