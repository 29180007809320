@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-primary: #2c25e9;
  --color-hover: #2c25e9;
  --background-color: white;
  --text-color: #000;
  --header-title-color: #526d82;
  --search-box-background: #dde6ed;
  --icon-color: #969393;
  --custom-card-bg: white;
}

body.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --header-title-color: #8fa3b0;
  --search-box-background: #333;
  --icon-color: #ffffff;
  --custom-card-bg: #1f1f2d;
}
* {
  transition: background-color 1s ease-in-out;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
}
.content--card-td {
  width: 100%;
  height: 999px;
  overflow-y: auto;
  padding-bottom: 10rem !important;
  padding: 1rem;
}
.content--card-td::-webkit-scrollbar {
  width: 0px;
}
.content--cards-charts-count {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.content-count-cards {
  display: flex;
  flex-direction: row;
}
.content-cards-count-cards {
  max-width: 900px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}
.cards--count-content {
  flex: 1;
  width: 100%;
  height: auto;
}
.grafico {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}

.card--count {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.card--grafico {
  cursor: pointer;
  width: 100%;
  background-color: var(--custom-card-bg);
  border-radius: 20px;
  padding: 20px;
  margin: 20px 0;
}
.card--grafico-content-grafico-barras-content {
  width: 85%;
}
.card--grafico-content-grafico-barras-content {
  border-radius: 20px;
  background: var(--custom-card-bg);
  padding: 2rem;
}
.content-grafico-barras {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card--grafico {
  color: var(--text-color);
  width: 100%;
}

/* card container */
.custom-card-bg {
  background-color: var(--custom-card-bg);
}
.icon {
  color: var(--color-primary);
}


.content-cards-count-cards--user{
display: flex;
flex-direction: row;
background-color: red;
}
.card{
  border:  none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);  
}

@media screen and (max-width: 1200px) {
  .content--cardUser,
  .content--graficos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    gap: 1;
    padding: 0;
  }
  .contenedor {
    width: 100%;
    display: flex;
    flex: 1;
  }
  .card--grafico {
    width: 100%;
    display: flex;
    flex: 1;
    gap: 0;
    padding: 0;
  }
  .content--header {
    padding-right: 0px;
  }
}

@media screen and (max-width: 999px) {
  .contenedor {
    display: flex;
    flex-direction: column !important;
  }
  .content--header {
    padding-left: 50px;
  }
  .content--cards-charts-count {
    flex-wrap: wrap;
  }
  .card--grafico-content-grafico-barras-content {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contenedor {
    display: flex;
    flex-direction: column !important;
  }
  .content--header {
    padding-left: 50px;
  }
  .greeting {
    font-size: 18px;
  }
  .current-time {
    font-size: 30px;
  }
}
